@import 'assets/styles/common/variables';

.navigation {
  margin-bottom: 10px;

  &-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-list-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    max-height: 100px;
    min-width: 16.6666667%;
    max-width: 16.6666667%;

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding: 18px 0 14px;
      background-color: #fff;
      border: 1px solid var(--body-bg);
      text-decoration: none;

      &.active {
        background-color: var(--primary);
        border-color: var(--primary);

        .navigation-list-item-icon {
          filter: brightness(0) invert(100%);
        }

        .navigation-list-item-title {
          color: var(--white);
        }
      }
    }
  }

  &-list-item-icon {
    width: 40px;
    height: 40px;
    user-select: none;
    margin: 0 auto;
  }

  &-list-item-title {
    font-family: var(--font-family-montserrat);
    font-size: 10px;
    font-weight: bold;
    margin: 0;
    user-select: none;
    padding: 0 15px;
    color: var(--body-color);
  }

  &-back-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    background-color: var(--mine-shaft);
    margin-top: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: calc((100vw - 764px) / 2);
    transition: 0.1s ease-out;

    &.hidden {
      opacity: 0;
    }
  }
}
