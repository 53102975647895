.foils-page {
  form {
    margin-top: 30px;
  }

  .form-group--radio p {
    font-weight: bold;
  }
}

.foil-results {
  > p {
    font-size: 1.16667rem;
    font-family: var(--font-family-montserrat);
    font-weight: 800;
  }
}

.foils {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  &__item {
    border: 4px solid var(--body-bg);
    padding: 5px;
    text-align: center;
    margin: 5px;
    color: var(--lipstick);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    transition: 0.1s ease-out;

    @media (max-width: 784.98px) {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }

    @media (min-width: 785px) {
      flex: 0 0 calc(25% - 10px);
      max-width: calc(25% - 10px);
    }

    &:hover {
      border-color: var(--yellow);
      background-color: var(--yellow);
      color: var(--white);

      .foils__item__name {
        color: var(--white);
      }

      .foils__item__button {
        text-decoration: none;
      }
    }

    &__image {
      margin-bottom: 10px;
    }

    &__name {
      margin: auto 5px 6px;
      display: block;
      font-weight: bold;
      color: var(--semi-dark);
    }

    &__button {
      text-decoration: underline;
      display: inline-block;
      font-family: var(--font-family-montserrat);
      font-weight: 900;
      text-transform: uppercase;
      font-size: 0.83334em;
      letter-spacing: 0.071em;
      align-self: center;
    }
  }
}
