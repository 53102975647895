h1,
.h1 {
  font-family: $font-family-open-sans;
  font-size: 2.5rem;
  font-weight: 400;
  margin: 0 0 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

h2,
.h2 {
  font-size: 1.66667rem;
  font-weight: 500;
  margin: 0 0 0.5em;
}
