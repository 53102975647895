.carousel {
  position: relative;
}

.carousel-image {
  object-fit: cover;
}

.carousel-placeholder {
  width: 100%;
  height: 330px;
  background: var(--gray);
}

.carousel-left-arrow,
.carousel-right-arrow {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #050a15;
  transform: translateY(-50%);

  &.disabled {
    background-color: #ededeb;
    cursor: default;
  }
}

.carousel-right-arrow {
  right: 0;
}

.carousel-left-arrow {
  left: 0;
}

.carousel-slide {
  opacity: 0;
  transition: opacity 500ms ease-in-out;

  &.active {
    opacity: 1;
  }
}
