.table {
  width: 100%;
  margin-bottom: 15px;

  &.table-striped {
    background-color: transparent;
    border-color: transparent;
    border-spacing: 0;

    tr:nth-of-type(odd):not(.table-heading) {
      background-color: #ededeb;
    }
  }

  .table-heading {
    background-color: #333;

    > td {
      color: #fff;
      padding: 4px 8px;
      height: 22px;
    }
  }

  .table-subheading {
    > td {
      padding: 4px 8px;
      height: 22px;
      border-bottom: 2px solid #333;
      background-color: #fff;
    }
  }

  tr td {
    padding: 10px;
    line-height: 1.8;
    vertical-align: middle;
    height: 30px;

    &:nth-child(1) {
      text-align: left;
      width: 62.7266%;
    }

    &:nth-child(2) {
      text-align: right;
      width: 37.2734%;
    }

    a {
      color: #fff;
      background-color: var(--lipstick);
      padding: 2px 5px;
      font-weight: bold;
      text-decoration: none;
      margin-left: 5px;
    }

    strong {
      font-size: 10px;
    }
  }
}

.data-table {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  &-row {
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 15px;

    &:not(:last-of-type) {
      border-bottom: 2px solid $desert-storm;
    }
  }

  &-label {
    flex: 0 0 40%;
    padding-right: 5px;
  }

  &-content {
    flex: 0 0 60%;
    font-weight: bold;
  }
}
