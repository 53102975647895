.accordion {
  margin: 20px 0;

  &-item {
    background-color: #fff;

    &:not(:last-child) {
      border-bottom: 4px solid #ededeb;
    }
  }

  &-header {
    margin: 0;
  }

  &-body {
    padding: 16px 10px;
  }

  &-collapse {
    &:not(.show) {
      display: none;
    }
  }

  &-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 800;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    cursor: pointer;

    &:after {
      content: '';
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-left: auto;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik05Ljk4MiAxSDguMDE4djEyLjE2NEwyLjQwNCA3LjU5NiAxIDlsOCA4IDgtOC0xLjQwNC0xLjQ1LTUuNjE0IDUuNjE0eiIgZmlsbD0iI0RBMDgxMiIgc3Ryb2tlPSIjREEwODEyIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==);
      background-repeat: no-repeat;
      background-size: 20px;
      transition: transform 0.2s ease-in-out;
    }

    &:not(.collapsed) {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }
}
