$white: #fff;
$black: #000;
$dark: #111;
$semi-dark: #222;
$mine-shaft: #333;
$desert-storm: #ededeb;
$yellow: #fdab1c;
$lipstick: #e42730;
$gray: #d6d6d6;

$primary: $yellow;
$danger: $lipstick;

$body-color: $mine-shaft;
$body-bg: $desert-storm;
$base-font-size: 12px;

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-open-sans: 'Open Sans', sans-serif;

$font-family-base: $font-family-open-sans;

$spacers: (
  '10px': 10px,
  '20px': 20px,
  '30px': 30px,
  '40px': 40px,
  '50px': 50px,
  '60px': 60px,
  '70px': 70px,
  '80px': 80px,
  '90px': 90px,
  '100px': 100px
);
