.List {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.ListItem {
  display: flex;
  width: 20%;
  max-width: 20%;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 3% 3%;
}

.ListItemSkeleton {
  background-color: var(--gray);
  height: 150px;
  width: 15%;
  margin: 0 2.5% 6%;
}

.Title {
  margin: 7px 0 0 0;
  font-family: var(--font-family-montserrat);
}

.Image {
  width: 100px;
  height: 145px;
  object-fit: cover;
  border: solid 1px #e3e3e1;
}

.DownloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: var(--lipstick);
  border-radius: 50%;
  cursor: pointer;
  margin-top: -15px;
  border: 0;
  box-shadow: none;
}
