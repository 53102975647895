.page-404 {
  background-color: var(--body-bg);
  text-align: center;
  margin: -20px;
  padding: 50px 20px 40px;
  border: 2px dotted var(--body-color);

  h1 {
    font-size: 1.66667rem;
    font-family: var(--font-family-montserrat);
    font-weight: 500;
    margin-top: 0.75em;
    color: var(--dark);
  }
}
