.banners,
.banners-loader {
  height: 185px;
  background-color: var(--gray);
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-title {
  margin: 0 0 20px 0;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
}

.banner-button {
  padding: 10px;
  background-color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 0.42px;
  color: var(--body-color);
  text-decoration: none;
  text-transform: uppercase;
}

.banner-slider {
  position: relative;
  height: 100%;
}

.banner-slide {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 20px 40px 20px 20px;
  opacity: 0;
  transition: opacity 500ms ease-in-out visibility 500ms ease-in-out;

  &.active {
    opacity: 1;
  }

  &:not(.active) {
    visibility: hidden;
  }
}

.slider-pagination {
  list-style: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  li {
    width: 8px;
    height: 8px;
    text-indent: -9999px;
    border: 1px solid var(--white);
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &.active {
      background-color: var(--white);
    }
  }
}
