.Banner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 17px 20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.BannerTitle {
  font-family: var(--font-family-open-sans);
  font-size: 30px;
  color: var(--white);
  margin: 0 0 20px;
}

.BannerContent {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}

.BannerButton {
  font-family: var(--font-family-montserrat);
  padding: 8px 10px;
  background-color: var(--danger);
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 0.42px;
  color: var(--white);
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
  cursor: pointer;
}

.BannerImage {
  width: 90px;
  height: 130px;
  object-fit: cover;
}
