.searchPanel {
  background-color: var(--white);
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-family: var(--font-family-montserrat);
  }
}

.submit,
.reset {
  position: absolute;
  top: 2px;
  width: 36px;
  height: 36px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--body-bg);
  }
}

.submit {
  left: 2px;
  transition: background-color 0.1s ease-out;

  img {
    transform: translateY(1px);
  }
}

.reset {
  right: 2px;
  transition: opacity 0.1s ease-out, background-color 0.1s ease-out;

  &[disabled] {
    opacity: 0;
  }
}

.field {
  padding: 5px 40px;
  width: 100%;
}
