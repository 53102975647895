.consultant-page {
  h1 {
    margin-bottom: 0.66667em;
  }
}

.consultants {
  display: flex;
  margin-bottom: 10px;

  @media (max-width: 784.98px) {
    flex-direction: column;
  }
}

.consultant {
  display: flex;

  @media (max-width: 784.98px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 785px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  img {
    border-radius: 50%;
    flex: 0 0 80px;
    max-width: 80px;
    align-self: flex-start;
    margin-right: 20px;
  }

  a {
    color: var(--body-color);

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 0 0 0.4167em;
  }

  &__name {
    font-family: var(--font-family-montserrat);
    font-size: 1rem;
    margin: 0 0 0.4167em;
  }

  &__title {
    font-size: 0.83334rem;
    font-style: italic;
  }

  &__link {
    text-decoration: underline;
    font-weight: bold;
  }
}
