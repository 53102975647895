.app-container {
  max-width: 785px;
  padding: 0 20px 20px 21px;
  margin: 0 auto;
}

.app-content {
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
}

.banners-and-search {
  @media (min-width: 785px) {
    display: flex;

    > div {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.clipboard-feedback {
  background: #fff;
  border: 1px solid #f00;
  padding: 2px 5px;
  position: fixed;
  z-index: 999;
  animation: clipboardFeedbackAnimation ease-in infinite;
}

@keyframes clipboardFeedbackAnimation {
  0%,
  100% {
    opacity: 0;
  }
  0%,
  70% {
    transform: translateX(0);
  }
  5%,
  80% {
    opacity: 1;
  }
  75% {
    transform: translateX(-1%);
  }
  100% {
    transform: translateX(25%);
  }
}

ol > ::marker {
  font-weight: 800;
  color: #050a15;
}
