.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 4px solid var(--body-bg);
    margin: 0 0 25px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    list-style: none;
    margin-right: 50px;
    text-align: center;
    cursor: pointer;
    position: relative;

    &--selected {
      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        display: block;
        height: 4px;
        width: 100%;
        background-color: var(--mine-shaft);
      }
    }

    &--disabled {
      color: var(--body-bg);
      cursor: default;
    }

    &:focus {
      outline: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }

  &__image {
    height: 40px;
  }

  &__title {
    font-family: var(--font-family-montserrat);
    font-weight: 800;
    letter-spacing: 0.04167em;
    text-transform: uppercase;
    margin: 5px 0 15px;
  }
}
