* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

:root {
  --font-family-open-sans: #{$font-family-open-sans};
  --font-family-montserrat: #{$font-family-montserrat};
  --font-family-base: #{$font-family-base};
  --white: #{$white};
  --black: #{$black};
  --dark: #{$dark};
  --semi-dark: #{$semi-dark};
  --mine-shaft: #{$mine-shaft};
  --desert-storm: #{$desert-storm};
  --yellow: #{$yellow};
  --lipstick: #{$lipstick};
  --gray: #{$gray};
  --primary: #{$primary};
  --danger: #{$danger};
  --body-bg: #{$body-bg};
  --body-color: #{$body-color};
  --font-size-base: #{$base-font-size};
}

html {
  font-size: var(--font-size-base, $base-font-size);
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-family-base, $font-family-base);
  color: var(--color, $body-color);
  background-color: var(--body-bg, $body-bg);
}

ol {
  margin: 0;
  padding-left: 20px;

  li {
    margin-bottom: 10px;
  }
}

ul {
  margin: 0;
  padding: 0;

  &.list {
    margin: 0 0 30px 20px;

    li {
      position: relative;
      list-style-type: none;
      margin-bottom: 12px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -17px;
        transform: translateY(-50%);
        display: block;
        width: 6px;
        height: 6px;
        background-color: var(--lipstick);
      }
    }
  }
}

p {
  a {
    color: var(--lipstick);
    font-weight: bold;
  }
}

[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.not-found {
  background-color: var(--body-bg);
  text-align: center;
  padding: 45px 20px 35px;
  border: 2px dotted var(--body-color);

  h2 {
    font-size: 1.66667rem;
    font-family: var(--font-family-montserrat);
    font-weight: 500;
    margin: 0.75em auto 0;
    color: var(--dark);
    max-width: 260px;
  }
}

.highlight {
  background-color: var(--primary);
}
