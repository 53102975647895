.form-group {
  margin-bottom: 1.33333rem;
  position: relative;

  &--radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      display: block;
      cursor: pointer;
      padding: 3px 0 3px 22px;

      &:not(:first-of-type) {
        margin-top: 5px;
      }

      > span {
        position: relative;

        &:before,
        &:after {
          box-sizing: border-box;
          content: '';
          border-radius: 50%;
          position: absolute;
          transition: 0.1s ease-out;
        }

        &:before {
          border: 2px solid var(--lipstick);
          width: 16px;
          height: 16px;
          top: 0;
          left: -22px;
        }

        &:after {
          background-color: var(--lipstick);
          width: 10px;
          height: 10px;
          z-index: 1;
          top: 3px;
          left: -19px;
        }
      }
    }

    input {
      display: none;

      &:not(:checked) {
        ~ span {
          &:before {
            border-width: 1px;
            border-color: var(--body-color);
          }
          &:after {
            transform: scale(0);
          }
        }
      }
    }
  }
}

.form-control {
  border-radius: 0;
  border: 2px solid var(--body-bg);
  transition: border-color 0.1s ease-out;

  &:focus {
    outline: none;
  }

  &[type='text'] {
    height: 40px;

    &:focus {
      border-color: var(--body-color);
    }
  }
}
